import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import {withPrefix} from "../../../.cache/gatsby-browser-entry";
import Image from "../../components/Image";

const StudyGuidePage = ({
    data,
    pageContext: {
      breadcrumb: { crumbs },
    },
}) => {
  const {studyGuidePage: page} = data;
  
  // Add Guide page link as a second crumb element.
  crumbs.splice( 1, 0, {pathname: "/student-guide", crumbLabel: "Guide"});
  // Add Guide category page link as third crumb element.
  crumbs[2] = {pathname: withPrefix(page.sections[0].section), crumbLabel: page.categoryPages[0].categoryPage};
  
  crumbs = [
    {pathname: "/", crumbLabel: "Home"},
    {pathname: "/student-guide", crumbLabel: "Guide"},
    {pathname: withPrefix(page.sections[0].section), crumbLabel: page.categoryPages[0].categoryPage},
    {pathname: withPrefix(page.path), crumbLabel: page.title}
  ];
  
  return (
      <Layout>
        <SEO title={page.title} />

        <CustomBreadcrumbs crumbs={crumbs} path={page.path} title={page.title} />
        
        <h1>{page.title}</h1>
  
        { !!page.image && <Image imgName={page.image} /> }
        
        <div className="page-body" dangerouslySetInnerHTML={{__html: page.body}}/>
      </Layout>
  )
};

StudyGuidePage.propTypes = {
  data: PropTypes.shape({
    studyGuidePage: PropTypes.object
  })
};

export default StudyGuidePage

export const pageQuery = graphql`
    query($id: String!) {
        studyGuidePage(nid: {eq: $id}) {
            type
            title
            body
            image
            sections {
                section
            }
            categoryPages {
                categoryPage
            }
        }
    }
`;
